body { 
  display: flex; 
  flex-direction: column;
  align-items: center;
  max-width: 60em; 
  margin: 0 auto; 
  background-color: #dddddd;
}

.container { 
  background-color: #0077b3;
  margin: 0.5em;
}
