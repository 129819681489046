.form {
  text-align: center; 
  padding: 2% 15%;
  color: white;
  font-size: 150%;
}

.form-control, .btn {
    height: 2.0em;
    width: 100%;
    padding: 0 0.5em;
    margin: 0.5em 0;
}